<!--
 * @version: 1.0
 * @Date: 2023-04-23 14:17:32
 * @LastEditors: chenxu chenxu@libawall.cn
-->
<template>
  <div>
    <!-- 表单 -->
    <a-form :model="formData" layout="vertical" class="form">
      <a-form-item label="是否设置抄送人">
        <a-radio-group v-model:value="ccType" :disabled="allDisabled">
          <a-radio value="1" style="fontSize: 14px">否</a-radio>
          <a-radio value="2">是</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 选择设置抄送人 -->
      <a-form-item label="抄送人设置" v-if="ccType === '2'">
        <a-radio :checked="true" :disabled="allDisabled">指定人员</a-radio>
      </a-form-item>
      <!-- 添加成员 -->
      <div class="person" v-if="ccType === '2'">
        <div class="person-header">
          添加成员
          <span class="person-header-right">（最多30位）</span>
        </div>
        <div class="personel">
          <div class="per-item" v-for="(item, index) in assignedUser" :key="item.id">
            {{ item.dataName }}
            <CloseOutlined @click="delPerson(index)" class="item-icon" v-if="!allDisabled" />
          </div>
          <i
            :class="['iconfont', 'icon-add', 'icon-add-right']"
            v-if="assignedUser?.length < 30 && !allDisabled"
            @click="addPerson"
          ></i>
        </div>
      </div>
    </a-form>
    <!-- TODO :统一选择成员 -->
    <a-modal
      centered
      title="添加成员"
      width="655px"
      class="global-range-modal"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <GlobalSelector
        :selectorInfo="selectorInfo"
        :visible="visible"
        :selectedList="assignedUser"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      ></GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
// import RangeModal from '@/components/rangeModal'
import { CloseOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'

export default {
  name: 'CcConfig',
  components: {
    // RangeModal,
    CloseOutlined,
    GlobalSelector
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showConfig: {
      type: Boolean,
      defaulte: false
    },
    isCancel: {
      type: Boolean,
      defaulte: false
    }
  },

  data() {
    return {
      formData: {}, // 发起/审批
      assignedUser: [],
      ccType: '1',
      selectorInfo: {
        abelCheckAll: true,
        limitCount: 30,
        placeholder: '请输入员工姓名或部门',
        ableSelectDep: true,
        ableChangeData: true
      },
      visible: false,
      renderList: [],
      selectorType: 1
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  watch: {
    showConfig: {
      deep: true,
      handler(value) {
        // console.log('showConfig',value);
        // console.log(11222,this.isCancel)
        // console.log(this.config);
        console.log(this.selectedNode)
        const select = JSON.parse(JSON.stringify(this.selectedNode.props))
        if (value) {
          if (!this.isCancel) {
            // console.log('抽屉显示', select);
            this.ccType = select?.ccType ? '2' : '1'
            select.cc.assignedUser?.forEach(item => {
              item.dataType = 1
            })
            this.assignedUser = select.cc.assignedUser
          }
        } else {
          // 没点保存
          if (!this.isCancel) {
            // this.assignedUser = []
            this.ccType = '1'
          } else {
            // 点了保存
            this.assignedUser?.forEach(item => {
              item.dataType = 'user'
            })
            this.selectedNode.props.ccType = this.ccType === '2'
            this.selectedNode.props.cc =
              this.ccType === '2' ? { assignedUser: this.assignedUser } : { assignedUser: [] }
          }
        }
      }
    }
  },

  created() {
    this.getScopeList()

    console.log(9999999)
    const select = JSON.parse(JSON.stringify(this.selectedNode.props))
    select.cc.assignedUser?.forEach(item => {
      item.dataType = 1
    })
    this.assignedUser = select.cc.assignedUser
    this.ccType = select.ccType ? '2' : '1'
  },
  methods: {
    // 添加成员
    addPerson() {
      // console.log(this.config);
      this.visible = true
    },
    // 删除成员
    delPerson(index) {
      this.assignedUser.splice(index, 1)
    },

    //弹窗取消事件
    handleCancel() {
      this.visible = false
      this.$refs.globalSelector.cancelEvent()
    },
    //弹窗确定事件
    handleOk() {
      this.assignedUser = []
      let arr = [...this.$refs.globalSelector.okEvent()]
      transformData(arr).then(res => {
        res.forEach(item => {
          this.assignedUser.push({
            dataId: item.dataId,
            dataName: item.dataName,
            dataType: 1,
            sex: null,
            selected: true,
            icon: item?.icon,
            mobile: item?.mobile
          })
        })
      })
      this.handleCancel()
    },
    //搜索
    getSearchInfo(e) {
      let data = {
        searchName: e
      }
      this.getScopeList(data)
    },
    //去下级
    toNext(id) {
      let data = {
        departmentId: id
      }
      this.getScopeList(data)
    },
    //弹窗数据
    async getScopeList(data) {
      let params = {
        selectorType: this.selectorType, //1部门人员2部门3员工
        allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        this.handelData(res)
      }
    },
    // 处理左侧数据
    handelData(res) {
      this.renderList = formateListData(res)
      // console.log('处理后的renderList的值', this.renderList)
    },
    changeData({ isOrganizationData, searchValue }) {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        this.selectorType = 1
      } else {
        //人员数据
        this.selectorType = 3
      }
      this.getSearchInfo(searchValue)
    }
  }
}
</script>

<style scoped lang="less">
.form {
  :deep(.ant-form label) {
    font-size: 16px;
  }
  .person {
    .person-header {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      .person-header-right {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .personel {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      display: flex;
      flex-flow: wrap;
      margin: 12px 0px;
      .per-item {
        background-color: #fbfbfc;
        border: 1px solid #dcdee0;
        color: rgba(0, 0, 0, 0.65);
        padding: 2px 4px;
        font-size: 14px;
        margin-right: 12px;
        margin-bottom: 12px;
        border-radius: 2px;
        .item-icon {
          font-size: 12px;
          color: #969799;
        }
      }
      .icon-add-right {
        color: #0A7BFF;
        cursor: pointer;
        font-size: 24px;
        margin-top: -6px;
      }
    }
  }
  .text {
    background-color: #fbfbfb;
    border-radius: 2px;
    padding: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 26px;
  }
}
</style>
